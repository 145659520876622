import React from 'react';
import ReactDOM from 'react-dom';
//import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './i18n';
import i18n from 'i18next';
import reportWebVitals from './reportWebVitals';
//import './index.css';
import loadable from '@loadable/component';
const App = loadable(() => import('./App'));
//import App from './App';


i18n.on('initialized', () => {
  ReactDOM.hydrate(<App />, document.getElementById('root'));
});
// ReactDOM.render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
